import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import Aside from "../../../components/Aside";
import Footer from "../../../components/Footer";
import moment from 'moment';
import 'moment/locale/es'
import { useRef, useState, useEffect } from "react";
import Tooltip from "../../../components/Tooltip";
import { ReportService } from "../../../services/reportService";
import { SegmentService } from "../../../services/segmentService";
import { round } from "../../../utils/number";

moment.locale('es')

declare const $: any;

function MaterialsDetail() {

    const navigate = useNavigate();

    const { state: { prevHighlights, range } } = useLocation()

    const rangeElement = useRef(null)

    const [rangeFilter, setRangeFilter] = useState({
        start: moment(range.start),
        end: moment(range.end),
        isFirstRender: true
    })

    const [list, setList] = useState([])

    const [highlights, setHighlights] = useState(prevHighlights)
    const [segmentations, setSegmentations] = useState([])
    
    const [filter, setFilter] = useState({
        types: [],
        segmentations: [],
    })

    const getDashboardMaterials = async () => {
        try {
            const request = await ReportService.getDashboardContent(rangeFilter.start.format('YYYY-MM-DD'), rangeFilter.end.format('YYYY-MM-DD'))
            setHighlights(request)
        } catch (error) {
            console.error(error)
        }
    }

    const handleFilter = (key: 'types'|'segmentations', field: string, value: boolean ) => {
        let list  = filter[key]


        if(field === 'all') {
            list = []
        } else if(value) {
            list.push(field)
        } else {
            list = list.filter( l => l !== field)
        }

        setFilter({
            ...filter,
            [key]: list
        })
    }

    const getSegmentations = async() => {
        const data = await SegmentService.getSegments();
        setSegmentations(data)
    }

    const getReport = async () => {
        try {
            await getSegmentations()
            const request = await ReportService.getMaterialsDetail(rangeFilter.start.format('YYYY-MM-DD'), rangeFilter.end.format('YYYY-MM-DD'))
            
            setList(request)
        } catch (error) {
            console.error(error)
        }
    }

    const formatType = (type) => {
        switch (type) {
            case 'PDF':
                return <span className="badge badge-pdf">PDF</span>
            case 'IMAGE':
                return <span className="badge badge-image">Imagen</span>
            case 'VIDEO':
                return <span className="badge badge-video">Video</span>
            case 'AUDIO':
                return <span className="badge badge-audio">Audio</span>
            case 'LINK':
                return <span className="badge badge-link">Link</span>
            case 'FORM':
                return <span className="badge badge-form">Encuesta</span>
            case 'POLL':
                return <span className="badge badge-poll">Votación</span>

            default:
                break;
        }
    }

    useEffect(() => {
        if (!rangeElement || !rangeElement.current) return;

        $(rangeElement.current).find('.js-daterangepicker-predefined-preview').html(rangeFilter.start.format('MMMM D, YYYY') + ' - ' + rangeFilter.end.format('MMMM D, YYYY'));

        const cb = (start, end) => {
            setRangeFilter({
                start,
                end,
                isFirstRender: false
            })

            $(rangeElement.current).find('.js-daterangepicker-predefined-preview').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        }

        $(rangeElement.current).daterangepicker({
            startDate: rangeFilter.start,
            endDate: rangeFilter.end,
            ranges: {
                'Hoy': [moment(), moment()],
                'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
                'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
                'Mes actual': [moment().startOf('month'), moment().endOf('month')],
                'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                customRangeLabel: 'Personalizado',
                daysOfWeek: [
                    "Do",
                    "Lu",
                    "Ma",
                    "Mi",
                    "Ju",
                    "Vi",
                    "Sa"
                ],
                monthNames: [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                ],
            }
        }, cb);

    }, [rangeElement])

    useEffect(() => {
        if (!rangeFilter.isFirstRender) {
            getDashboardMaterials()
        }
        getReport()
    }, [rangeFilter])


    return (
        <>
            <Header>
                <div>
                    <h4>Detalle de materiales</h4>
                    <p>Consulte las estadísticas asociadas a cada material de acuerdo al periodo de tiempo seleccionado.</p>
                </div>

                <div className='d-flex ms-auto'>
                    <button ref={rangeElement} className="btn btn-primary btn-sm">
                        <i className="bi-calendar-week me-2"></i>
                        <span className="js-daterangepicker-predefined-preview"></span>
                    </button>
                </div>

            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="content container px-5">
                    <div className="row">

                        <div className="col-12 m-b-24">
                            <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(-1)} ><i className="bi bi-arrow-left me-2"></i>  Dashboard</button>
                        </div>

                        <div className='col-12 m-b-24'>
                            <div className="card ">
                                <div className="card-body d-flex">
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{highlights.total_materials}</h3>
                                        <p className='nowrap'>Total enviados <Tooltip className='ms-1 color-icon' title='Total de plantillas de material enviadas.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{highlights.total_unique_sent}</h3>
                                        <p className='nowrap'>Envios únicos <Tooltip className='ms-1 color-icon' title='Total de plantillas de material enviadas a contactos únicos.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{highlights.total_opened}</h3>
                                        <p className='nowrap'>Clics totales <Tooltip className='ms-1 color-icon' title='Total de clics de contactos únicos a plantillas de material.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{highlights.total_viewed}</h3>
                                        <p className='nowrap'>Aperturas <Tooltip className='ms-1 color-icon' title='Total de aperturas de materiales por contactos únicos.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>

                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{ round((highlights.total_opened * 100) / highlights.total_unique_sent)}%</h3>
                                        <p className='nowrap'>Click rate <Tooltip className='ms-1 color-icon' title='Clics totales x 100 / envíos únicos' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{ round((highlights.total_viewed*100) / highlights.total_opened)}%</h3>
                                        <p className='nowrap'>Click-to-open rate <Tooltip className='ms-1 color-icon' title='Aperturas x 100 / clics totales' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-12 m-b-24">
                            <div className="">
                                <p className="text-dark fw-semibold me-3">Filtrar por:</p>

                                <div className="btn-group" role="group" aria-label="Basic outlined styles example">
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-outline-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                            Tipo de material { filter.types.length > 0 && <span className="badge bg-primary text-white ms-1">{filter.types.length}</span>}
                                        </button>
                                        <ul className="dropdown-menu" >
                                            <h6 className="dropdown-header">Selecciona un tipo de material </h6>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="all-materials" className="form-check-input" checked={ !filter.types.length }  onChange={ ({ target: { checked }}) => handleFilter('types', 'all', checked )} />
                                                    <label className="form-check-label" htmlFor="all-materials">Todos</label>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="PDF" className="form-check-input" checked={ filter.types.indexOf('PDF') !== -1 }  onChange={ ({ target: { checked }}) => handleFilter('types', 'PDF', checked )} />
                                                    <label className="form-check-label" htmlFor="PDF">PDF</label>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="IMAGE" className="form-check-input" checked={ filter.types.indexOf('IMAGE') !== -1 }  onChange={ ({ target: { checked }}) => handleFilter('types', 'IMAGE', checked )} />
                                                    <label className="form-check-label" htmlFor="IMAGE">Imagen</label>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="VIDEO" className="form-check-input" checked={ filter.types.indexOf('VIDEO') !== -1 }  onChange={ ({ target: { checked }}) => handleFilter('types', 'VIDEO', checked )} />
                                                    <label className="form-check-label" htmlFor="VIDEO">Video</label>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="AUDIO" className="form-check-input" checked={ filter.types.indexOf('AUDIO') !== -1 }  onChange={ ({ target: { checked }}) => handleFilter('types', 'AUDIO', checked )} />
                                                    <label className="form-check-label" htmlFor="AUDIO">Audio</label>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="LINK" className="form-check-input" checked={ filter.types.indexOf('LINK') !== -1 }  onChange={ ({ target: { checked }}) => handleFilter('types', 'LINK', checked )} />
                                                    <label className="form-check-label" htmlFor="LINK">Link</label>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="FORM" className="form-check-input" checked={ filter.types.indexOf('FORM') !== -1 }  onChange={ ({ target: { checked }}) => handleFilter('types', 'FORM', checked )} />
                                                    <label className="form-check-label" htmlFor="FORM">Encuesta</label>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="POLL" className="form-check-input" checked={ filter.types.indexOf('POLL') !== -1 }  onChange={ ({ target: { checked }}) => handleFilter('types', 'POLL', checked )} />
                                                    <label className="form-check-label" htmlFor="POLL">Votación</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-outline-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                                            Segmentos { filter.segmentations.length > 0 && <span className="badge bg-primary text-white ms-1">{filter.segmentations.length}</span>}
                                        </button>
                                        <ul className="dropdown-menu" >
                                            <h6 className="dropdown-header">Selecciona un segmento</h6>
                                            <li className="dropdown-item">
                                                <div className="form-check">
                                                    <input type="checkbox" id="all-segmentations" className="form-check-input" checked={ !filter.segmentations.length }  onChange={ ({ target: { checked }}) => handleFilter('segmentations', 'all', checked )} />
                                                    <label className="form-check-label" htmlFor="all-segmentations">Todos</label>
                                                </div>
                                            </li>
                                            {
                                                segmentations.map( s => (
                                                    <li className="dropdown-item" key={s._id}>
                                                        <div className="form-check">
                                                            <input type="checkbox" id={s._id} className="form-check-input" checked={ filter.segmentations.indexOf(s._id) !== -1 } onChange={ ({ target: { checked }}) => handleFilter('segmentations', s._id, checked )} />
                                                            <label className="form-check-label" htmlFor={s._id}>{s.title}</label>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <table className="card-table table">
                        <thead>
                            <tr>
                                <th scope="col">Nombre </th>
                                <th scope="col" className="text-center">Tipo </th>
                                <th scope="col" className="text-center">Segmento(s)</th>
                                <th scope="col" className="text-center">Total envíos</th>
                                <th scope="col" className="text-center">Total únicos</th>
                                <th scope="col" className="text-center">Clics</th>
                                <th scope="col" className="text-center">Aperturas</th>
                                <th scope="col" className="text-center">Click rate </th>
                                <th scope="col" className="text-center">Click-to-open rate</th>
                                <th scope="col" className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list
                                    .filter( row => {

                                        if(filter.types.length === 0 && filter.segmentations.length === 0) {
                                            return true
                                        }

                                        if(filter.types.length > 0 && filter.segmentations.length === 0) {
                                            return filter.types.indexOf(row.content_repository.type) !== -1
                                        }

                                        if(filter.types.length === 0 && filter.segmentations.length > 0) {
                                            return row.content_repository.segmentations.filter( s => filter.segmentations.includes(s._id))
                                        }
                                        

                                        return filter.types.indexOf(row.content_repository.type) !== -1 && row.content_repository.segmentations.filter( s => filter.segmentations.includes(s._id))
                                    })
                                    .map(row => (
                                    <tr key={row._id}>
                                        <td>{row.content_repository.name}</td>
                                        <td className="text-center">{formatType(row.content_repository.type)}</td>
                                        <td className="text-center">{row.content_repository.segmentations.map( s => segmentations.find( seg => seg._id === s).title).join(', ')}</td>
                                        <td className="text-center">{row.total_sent}</td>
                                        <td className="text-center">{row.unique_sent}</td>
                                        <td className="text-center">{row.open_link}</td>
                                        <td className="text-center">{row.open_content}</td>
                                        <td className="text-center">{ round((row.open_link * 100) / row.unique_sent)}%</td>
                                        <td className="text-center">{ round((row.open_content * 100) / row.open_link)}%</td>
                                        <td className="">

                                            <div className="btn-group">
                                                <button className="btn btn-outline-primary btn-outline-edit btn-sm " onClick={() => navigate('/dashboard/materials/detail', { state: { material: row } })}>
                                                    Detalle
                                                </button>

                                                {
                                                    (row.content_repository.type === 'POLL' || row.content_repository.type === 'FORM') && (
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-primary btn-outline-edit btn-icon btn-sm dropdown-toggle dropdown-toggle-empty" data-bs-toggle="dropdown" aria-expanded="false"></button>

                                                            <div className="dropdown-menu dropdown-menu-end mt-1" >
                                                                <button className="dropdown-item" onClick={() => navigate(`/dashboard/materials/${row.content_repository.type === 'POLL' ? 'poll': 'survey' }`, { state: {material: row}})}>
                                                                    <i className="bi-clipboard-data"></i> Resultados
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>

                <Footer />
            </main>


        </>
    )
}
export default MaterialsDetail